<template>
  <base-modal
    popup-class="modal-xl"
  >
    <template #modalHeader>
      <header class="px-2.5 py-2 bg-custom-gray-10 flex justify-between items-center sticky top-0 z-50">
        <div class="flex items-center">
          <base-svg
            class="h-4 w-4 text-primary-gray inline-block mr-2"
            src="icons/chat.svg"
            :svg-attributes="{
              class: 'h-full w-full'
            }"
            tag="span"
          />
          <span class="text-black text-sm font-semibold">
            {{ modalTitle }}
          </span>
        </div>
        <base-svg
          class="h-5 w-5 text-custom-gray-7 inline-block hover:text-gray-900 cursor-pointer"
          src="icons/cross.svg"
          :svg-attributes="{
            class: 'h-full w-full'
          }"
          tag="span"
          @click="handleCancel()"
        />
      </header>
    </template>
    <template #modalBody>
      <brand-assurance-comments :modal-title="modalTitle" />
    </template>
  </base-modal>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
    name: 'BrandAssuranceCommentsModal',

    components: {
        BrandAssuranceComments: defineAsyncComponent(() => import('./BrandAssuranceComments.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue')),
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue'))
    },

    props: {
        modalTitle: {
            type: String,
            default: '',
            description: 'title for modal header'
        }
    },

    emits: [
        'hide'
    ],

    setup (props, { emit }) {
        const handleCancel = () => {
            emit('hide');
        };

        return {
            handleCancel
        };
    }
};
</script>
